import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import Layout from "../components/Layout";
import Features from "../components/Features";
import FullWidthImage from "../components/FullWidthImage";
import Content, { HTMLContent } from "../components/Content";

// eslint-disable-next-line
export const IndexPageTemplate = ({
  image,
  title,
  subtitle,
  mainpitch,
  intro,
  main,
  content,
  contentComponent
}) => {
  const heroImage = getImage(image) || image;
  const PageContent = contentComponent || Content;

  return (
    <div>
      <FullWidthImage img={heroImage} title={title} subheading={subtitle} />
      <section className="section section--gradient">
        <div className="container">
          <div className="section">
            <div className="columns">
              <div className="column is-10 is-offset-1">
                <div className="content">
                  <div className="content">
                    <div className="tile">
                      <h1 className="title">{mainpitch.title}</h1>
                    </div>
                    <div className="tile">
                      <h3 className="subtitle">{mainpitch.description}</h3>
                    </div>
                  </div>
                  <Features gridItems={intro.blurbs} />
                  
                  <div className="columns">
                    <div className="column is-7">
                      <h3 className="has-text-weight-semibold is-size-3">
                        {main.heading}
                      </h3>
                      <p>{main.description}</p>
                    </div>
                  </div>

                  <div className="columns">
                    <div className="column is-12">
                      <PageContent className="content" content={content} />
                    </div>
                  </div>

                  {/* <div className="columns">
                    <div className="column is-12">
                      <div id="fb-root"></div>
                      <div className="fb-page" data-href="https://www.facebook.com/Brierleyhillbaby/" data-tabs="timeline" data-width="500" data-height="1000" data-small-header="true" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="true"><blockquote cite="https://www.facebook.com/Brierleyhillbaby/" className="fb-xfbml-parse-ignore"><a href="https://www.facebook.com/Brierleyhillbaby/">Brierley Hill Babybank</a></blockquote></div>
                    </div>
                  </div> */}
                  
                  {/* <div className="column is-12">
                    <h3 className="has-text-weight-semibold is-size-2">
                      News
                    </h3>
                    <BlogRoll />
                    <div className="column is-12 has-text-centered">
                      <Link className="btn" to="/blog">
                        Read more
                      </Link>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  subtitle: PropTypes.string,
  mainpitch: PropTypes.object,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
  main: PropTypes.shape({
    heading: PropTypes.string,
    description: PropTypes.string,
  }),
  content: PropTypes.string,
  contentComponent: PropTypes.func
};

const IndexPage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <IndexPageTemplate
        contentComponent={HTMLContent}
        image={post.frontmatter.image}
        title={post.frontmatter.title}
        subtitle={post.frontmatter.subtitle}
        mainpitch={post.frontmatter.mainpitch}
        intro={post.frontmatter.intro}
        main={post.frontmatter.main}
        content={post.html}
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      html
      frontmatter {
        title
        subtitle
        image {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        mainpitch {
          title
          description
        }
        intro {
          blurbs {
            image {
              childImageSharp {
                gatsbyImageData(width: 150, quality: 64, layout: CONSTRAINED)
              }
            }
            text
            website
          }
        }
        main {
          heading
          description
        }
      }
    }
  }
`;
